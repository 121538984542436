'use server';

import { getRequestEvent } from 'solid-js/web';
import {
  CreateProductSet_DYO_OG7_Params,
  CreateProductSetResponse,
  createProductSet_DYO_OG7,
} from './shopify/createProductSetSizes.js';
/* } from './shopify/createProductSet.js'; */
import {
  PublishProductParams,
  PublishProductResponse,
  publishProductWithMedia_DYO_OG7,
} from './shopify/publishProductPromotion.js';
import { CfPagesEnv } from '~/global.js';
import { Logger, LogLevel } from '@repo/logger';
import { getCookie, setCookie, getEvent, getQuery } from 'vinxi/http';
// import { type } from '../../../../packages/ui/src/components/solidui/badge';
import { getOrders, OrdersResponse, Variables } from './shopify/getOrders.js';

const IMAGES_SVC: string = 'https://images.wrsr.io/v1';

const logger = new Logger('server', { minLevel: LogLevel.DEBUG });

export interface ServerResponse<T> {
  id: string;
  result: ServerResposneSuccess<T> | ServerResponseError;
}

export interface ServerResposneSuccess<T> {
  type: 'success';
  value: T;
}

export interface ServerResponseError {
  type: 'error';
  error: string;
}

export interface Identity {
  guid: string;
  hash: string;
  short: string;
}

export async function sha256Hash(message: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
  // return crypto.createHash('sha256').update(data).digest('hex');
}

export function shortHash(hash: string): string {
  return hash.substring(0, 8).toUpperCase();
}

export async function generateIdentity(): Promise<Identity> {
  const guid = crypto.randomUUID();
  const hash = await sha256Hash(guid);
  const short = shortHash(hash);

  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  logger.info(`GUID: ${guid}`);
  logger.info(`SHA-256 Hash: ${hash}`);
  logger.info(`Short Hash (8 chars, uppercase): ${short}`);
  return {
    guid,
    hash,
    short,
  };
}

// Mock implementation for local development
async function createSessionFallback(): Promise<SessionServiceCreateResponse> {
  const response: SessionServiceCreateResponse = {
    session_id: `mock-${crypto.randomUUID()}`,
    created: new Date().toISOString(),
    mock: true,
  };
  return response;
}

export async function getSessionId(): Promise<string | undefined> {
  // return undefined;
  // if (isServer) {
  // Lazy load the vinxi/http module
  // const { getCookie } = await import('vinxi/http');
  // const event: RequestEvent = getRequestEvent();
  const event = getEvent();
  return getCookie(event, 'session_id');
  // getCookie('session_id');
  // } else {
  //   return document.cookie.match(/session_id=([^;]+)/)?.[1];
  // }
}

interface UserSession {
  auth?: string;
  created: string;
  isMock?: boolean;
}

export async function ensureSession(): Promise<UserSession | undefined> {
  // return undefined;
  const sessionId = await getSessionId();

  if (!sessionId) {
    const response = await createSession({ id: crypto.randomUUID() });
    if (response.result.type === 'success') {
      const { session } = response.result.value;
      return {
        // id: session.session_id,
        auth: 'user',
        created: session.created,
        isMock: response.result.value.mock,
      };
    }
    return undefined;
  }

  return {
    // id: sessionId,
    auth: 'user',
    created: new Date().toISOString(), // We don't have created time for existing sessions
  };
}

export async function serverLogout(): Promise<void> {
  logger.info('serverLogout');
  // Remove the session cookie
  // setCookie('session_id', '', {
  //   path: '/',
  //   maxAge: 0,
  // });
}

export async function serverLogin() {
  logger.info('serverLogin');
  const response = await createSession({
    id: crypto.randomUUID(),
  });
  return response;

  // if (response.result.type === 'error') {
  //   throw new Error(response.result.error);
  // }

  // const { session: newSession } = response.result.value;

  // // Set cookie for persistence
  // setCookie('session_id', newSession.session_id, {
  //   path: '/',
  //   maxAge: 60 * 60 * 24, // 24 hours
  //   secure: true,
  //   httpOnly: true,
  //   sameSite: 'strict',
  // });
}

export interface SessionServiceCreateResponse {
  session_id: string;
  created: string;
  mock?: boolean;
}

export interface CreateSessionRequest {
  id: string;
  data?: ArrayBuffer;
}

export interface CreateSessionResponse {
  session: SessionServiceCreateResponse;
  mock: boolean;
}

async function safeCreateSession(
  request: CreateSessionRequest
): Promise<SessionServiceCreateResponse> {
  const env = await getEnv();
  if (typeof env.SESSIONS_SERVICE === 'undefined') {
    logger.warn(
      'External session service not available, using mock implementation'
    );
    return createSessionFallback();
  } else {
    const response = await env.SESSIONS_SERVICE.fetch('https://internal/v1', {
      method: 'POST',
      body: request.data,
    });
    if (!response.ok) {
      logger.error('Failed to create session', { status: response.status });
      return createSessionFallback();
    }
    const sessionResult: SessionServiceCreateResponse = await response.json();
    return sessionResult;
  }
}

export async function createSession(
  request: CreateSessionRequest
): Promise<ServerResponse<CreateSessionResponse>> {
  logger.info('createSession', { request });
  try {
    const sessionResult = await safeCreateSession(request);

    logger.info('createSession - Service Result', { sessionResult });

    // const session = await sessionResult.json<SessionServiceCreateResponse>();
    // return session.session_id;
    const result = {
      session: {
        session_id: sessionResult.session_id,
        created: sessionResult.created,
      },
      mock: sessionResult.mock ?? false,
    };
    logger.info('createSession - Parsed Result', {
      session_id: result.session.session_id,
      created: result.session.created,
    });

    // Set cookie for persistence
    // setCookie('session_id', sessionResult.session_id, {
    //   path: '/',
    //   maxAge: 60 * 60 * 24, // 24 hours
    //   secure: true,
    //   httpOnly: true,
    //   sameSite: 'strict',
    // });
    const event = getEvent();
    setCookie(event, 'session_id', sessionResult.session_id, {
      path: '/',
      maxAge: 60 * 60 * 24, // 24 hours
      secure: true,
      httpOnly: true,
      sameSite: 'strict',
    });
    return { id: request.id, result: { type: 'success', value: result } };
  } catch (error) {
    logger.error('createSession', { error });
    return {
      id: request.id,
      result: { type: 'error', error: 'Failed to create session' },
    };
  }
}

export interface CreateBandFromImageDataRequest {
  id: string;
  title: string;
  description: string;
  email: string;
  phone?: string;
  filename?: string;
  mimetype?: string;
  data: ArrayBuffer;
}

export interface CreateBandFromImageDataResponse {
  upload: ImageUploadResponse;
  product: CreateProductSetResponse;
  publish: PublishProductResponse;
}

export async function createBandFromImageData(
  request: CreateBandFromImageDataRequest
): Promise<ServerResponse<CreateBandFromImageDataResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    logger.info('createBandFromImageData', { request });

    const { id, title, description, filename, mimetype, data } = request;
    logger.info('createBandFromImageData', { id, len: data.byteLength });

    const uploadResult = await uploadImage(id, {
      data,
      mimetype: mimetype ?? 'image/jpeg',
      filename: filename ?? `band-${id}.jpg`,
    });
    if (uploadResult.result.type !== 'success')
      return { id, result: { type: 'error', error: 'Failed to upload image' } };

    logger.info('uploaded image', { uploadResult });
    const createBandResult = await createBandFromImageId({
      id,
      title,
      description,
      imageName: uploadResult.result.value.image.filename, //.id,
    });
    logger.info('createBandFromImageId', { createBandResult });
    if (createBandResult.result.type !== 'success')
      return { id, result: { type: 'error', error: 'Failed to create band' } };

    const payload = {
      upload: uploadResult.result.value,
      product: createBandResult.result.value.product,
      publish: createBandResult.result.value.publish,
    };
    const sessionId = await getSessionId();
    const metaData = {
      type: 'createBandFromImageData',
      sessionId: sessionId,
      payload,
      inputs: request,
      uploadResult,
      createBandResult,
    };
    const env = await getEnv();
    const result = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
      `/bands/byimageid/${uploadResult.result.value.image.id}`,
      id
    );
    const fullPath =
      createBandResult.result.value.product.productSet.product.id;
    const productIdString = fullPath.split('/').pop();
    const productId = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
      `/bands/byproductid/${productIdString}`,
      uploadResult.result.value.image.id
    );
    const r2obj = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
      id,
      JSON.stringify(metaData)
    );
    logger.info('saved product design link', {
      key: r2obj?.key,
      type: metaData.type,
      sessionId: metaData.sessionId,
      payload: metaData.payload,
      inputs: metaData.inputs,
      uploadResult: metaData.uploadResult,
      createBandResult: metaData.createBandResult,
    });
    return {
      id,
      result: {
        type: 'success',
        value: payload,
      },
    };
  } catch (error) {
    logger.error('createBandFromImageId', { error });
    return {
      id: request.id,
      result: { type: 'error', error: 'Failed to create band' },
    };
  }
}
/**
 * The band image is already in the image store.
 * This function is identical to createBandFromImageData above
 * except that it does not need to store the band image first
 */
export interface CreateBandProductRequest {
  bandGUID: string;
  bandTitle: string;
  bandDescription: string;
  userEmail: string;
  userPhone: string;
  imageGUID: string;
  imageMimetype: string;
  imageFilename: string;
  sessionCookieId: string;
  thumbDataurl: string;
  identityGuid: string;
  identityHash: string;
  identityShort: string;
  productTemplate: string;
}
export interface CreateBandProductResponse {
  bandGUID: string;
  type: string; // success / error
  error: string; // error message
  value: object;
  designId: string; // i.e. response.result.value.product.productSet.product.handle;
  previewUrl: string; // i.e. response.result.value.upload.url.toString();
  productUrl: string; // i.e. https://shop.wearshare.com/products/${designId}
  fullProductId: string; // e.g. gid://shopify/Product/7432160477274
  productIdString: string; // e.g. 7432160477274
}

export async function createBandProduct(
  request: CreateBandProductRequest
): CreateBandProductResponse {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  const response = {
    bandGUID: request.bandGUID,
    type: '',
  } as CreateBandProductResponse;
  try {
    logger.info('createBandProduct', { request });
    // check the environment
    let fails = '';
    const env = await getEnv();
    let VITE_WEARSHARE_IMAGESTORE_URL = '';
    if (
      !Object.hasOwn(env, 'VITE_SHOPIFY_TOKEN') ||
      env.VITE_SHOPIFY_TOKEN.length === 0
    ) {
      fails = fails + 'VITE_SHOPIFY_TOKEN,';
    }
    if (
      !Object.hasOwn(env, 'VITE_SHOPIFY_SHOPNAME') ||
      env.VITE_SHOPIFY_SHOPNAME.length === 0
    ) {
      fails = fails + 'VITE_SHOPIFY_SHOPNAME,';
    }
    if (
      !Object.hasOwn(env, 'VITE_WEARSHARE_IMAGESTORE_URL') ||
      env.VITE_WEARSHARE_IMAGESTORE_URL.length === 0
    ) {
      VITE_WEARSHARE_IMAGESTORE_URL = 'https://images.wrsr.io/v1';
    } else {
      VITE_WEARSHARE_IMAGESTORE_URL = env.VITE_WEARSHARE_IMAGESTORE_URL;
    }
    if (!env.WRSR_IO_DESIGNER_PRODUCTS) {
      fails = fails + 'WRSR_IO_DESIGNER_PRODUCTS';
    }
    if (fails.length > 0) {
      response.type = 'error';
      response.error = 'Environment item(s) missing: ' + fails;
    } else {
      const createBandResult = await createBandFromImageId({
        id: request.bandGUID,
        title: request.bandTitle,
        description: request.bandDescription,
        imageName: request.imageFilename,
      });
      // calls 	generateIdentity()
      //  		createProduct(id, {identity,title,description,imageName}, env)
      //				createProductSet_DYO_OG7(params, env)
      //			publishProduct(id, { productId: createProductResult.result.value.productSet.product.id, publicationId: 'gid://shopify/Publication/108770754650',}, env)
      //				publishProductWithMedia_DYO_OG7(params, env)
      logger.info('createBandFromImageId', { createBandResult });
      if (createBandResult.result.type !== 'success') {
        response.type = 'error';
        response.error = 'Failed to create band';
        response.value = createBandResult;
      } else {
        const imageURL = new URL(
          VITE_WEARSHARE_IMAGESTORE_URL + '/' + request.imageGUID
        );
        const payload = {
          upload: {
            image: {
              id: request.imageGUID,
              url: imageURL,
              filename: request.imageFilename,
              mimetype: request.imageMimetype,
              success: true,
            },
            url: imageURL,
          },
          product: createBandResult.result.value.product,
          publish: createBandResult.result.value.publish,
        };
        const sessionId = await getSessionId();
        const metaData = {
          type: 'createBandFromImageData',
          sessionId: sessionId,
          payload,
          inputs: request,
          uploadResult: {
            id: request.bandGUID,
            result: { type: 'success', value: payload.upload },
          },
          createBandResult,
        };
        const result = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
          `/bands/byimageid/${request.imageGUID}`,
          request.bandGUID
        );
        const fullPath =
          createBandResult.result.value.product.productSet.product.id;
        const productIdString = fullPath.split('/').pop();
        const productId = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
          `/bands/byproductid/${productIdString}`,
          request.imageGUID
        );
        const r2obj = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
          request.bandGUID,
          JSON.stringify(metaData)
        );
        logger.info('saved product design link', {
          key: request.bandGUID,
          type: metaData.type,
          sessionId: metaData.sessionId,
          payload: metaData.payload,
          inputs: metaData.inputs,
          uploadResult: metaData.uploadResult,
          createBandResult: metaData.createBandResult,
        });
        response.type = 'success';
        response.value = payload;
        response.designId = payload.product.productSet.product.handle;
        response.previewUrl = payload.upload.url.toString();
        response.productUrl =
          'https://shop.wearshare.com/products/' +
          payload.product.productSet.product.handle;
      }
    }
  } catch (error) {
    logger.error('createBandFromImageId', { error });
    response.type = 'error';
    response.error = 'Failed to create band - exception';
    response.value = error;
  }
  return response;
}

export interface CreateBandFromImageIdRequest {
  id: string;
  title: string;
  description: string;
  imageName: string;
}

export interface CreateBandFromImageIdResponse {
  product: CreateProductSetResponse;
  publish: PublishProductResponse;
}

export async function createBandFromImageId(
  request: CreateBandFromImageIdRequest
): Promise<ServerResponse<CreateBandFromImageIdResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    const { id, title, description, imageName } = request;

    // TODO(pbirch): Fetch image details from service and add to response payload

    const identity = await generateIdentity();
    const createProductResult = await createProduct(id, {
      identity,
      title,
      description,
      imageName,
    });
    logger.info('created product', { createProductResult });
    if (createProductResult.result.type !== 'success')
      return {
        id,
        result: {
          type: 'error',
          error: 'Failed to create product',
          createProductResult: createProductResult,
        },
      };

    const publishProductResult = await publishProduct(id, {
      productId: createProductResult.result.value.productSet.product.id,
      publicationId: 'gid://shopify/Publication/108770754650',
    });
    logger.info('published product', { publishProductResult });
    if (publishProductResult.result.type !== 'success')
      return {
        id,
        result: { type: 'error', error: 'Failed to publish product' },
      };

    // TODO(pbirch): poll the shopify API to detect image errors and notify for ready to view status instead of arbitrary delay

    return {
      id,
      result: {
        type: 'success',
        value: {
          product: createProductResult.result.value,
          publish: publishProductResult.result.value,
        },
      },
    };
  } catch (error) {
    logger.error('createBandFromImageId', { error });
    return {
      id: request.id,
      result: { type: 'error', error: 'Failed to create band' },
    };
  }
}

// Constants
const DEFAULT_MIME_TYPE = 'image/jpeg';

// Type definitions
export interface ImageUploadRequest {
  data: ArrayBuffer;
  mimetype?: string;
  filename?: string;
}

export interface ImageUploadResult {
  id: string;
  url: URL;
  filename: string;
  mimetype: string;
  meta: unknown[];
  required_signed: boolean;
  uploaded: string;
  checksum_type: string;
  checksum: number[];
  success: boolean;
}

export type ImageUploadResponse = {
  image: ImageUploadResult;
  url: URL;
};

/**
 * Uploads an image to the image service
 * @param id Request identifier
 * @param request Image upload request containing data and metadata
 * @returns Server response with upload result or error
 */
export async function uploadImage(
  id: string,
  request: ImageUploadRequest
): Promise<ServerResponse<ImageUploadResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  logger.info('uploadImage', { id, request });
  try {
    // Validate input
    if (!request.data || request.data.byteLength === 0) {
      return {
        id,
        result: {
          type: 'error',
          error: 'Invalid or empty image data',
        },
      };
    }

    // Prepare headers with proper typing
    const headers = new Headers({
      'Content-Type': request.mimetype || DEFAULT_MIME_TYPE,
    });

    logger.info('uploadImage - post image', { len: request.data.byteLength });
    // Make the request
    const response = await fetch(IMAGES_SVC, {
      method: 'POST',
      headers,
      body: request.data,
    });
    logger.info('uploadImage - response', { response });

    // Handle non-ok responses
    if (!response.ok) {
      const errorText = await response.text();
      return {
        id,
        result: {
          type: 'error',
          error: `Upload failed: ${response.status} - ${errorText}`,
        },
      };
    }

    // Parse and validate response
    const result = (await response.json()) as ImageUploadResult;

    logger.info('uploadImage - result', { result });
    // Validate required fields
    if (!result.id || !result.success) {
      return {
        id,
        result: {
          type: 'error',
          error: 'Invalid response from image service',
        },
      };
    }

    // Add the URL to the result
    result.url = new URL(`${IMAGES_SVC}/${result.id}`);

    return {
      id,
      result: {
        type: 'success',
        value: {
          image: result,
          url: result.url,
        },
      },
    };
  } catch (error) {
    logger.error('uploadImage', { error });
    return {
      id,
      result: {
        type: 'error',
        error: error instanceof Error ? error.message : 'Unknown upload error',
      },
    };
  }
}

export async function getEnv(): Promise<CfPagesEnv> {
  const event = getRequestEvent();
  // if (event === undefined) return await import.meta.env as any as CfPagesEnv;
  if (
    event != undefined &&
    event.nativeEvent.context.cloudflare &&
    event.nativeEvent.context.cloudflare.env
  ) {
    return event.nativeEvent.context.cloudflare.env;
  }
  return (await import.meta.env) as any as CfPagesEnv;
}

export interface ImageDescriptor {
  id: string;
  filename: string;
  mimetype: string;
  checksum: Array<number>;
}

export interface ShopifyProductDescriptor {
  title: string;
  description: string;
}

export type PublishProductPayload = {
  image: ImageDescriptor;
  product: ShopifyProductDescriptor;
};

export type PostProductResponse = {
  // identity: Identity,
  handle: string;
  url: URL;
  mediaCount: number;
  /**
   * PROCESSING status means the image isn't ready to display
   * TODO(pbirch): poll shopify to detect image errors and notify for ready to view status instead of arbitrary delay
   */
  mediaStatus: Array<string>;
};

export async function createProduct(
  id: string,
  params: CreateProductSet_DYO_OG7_Params
): Promise<ServerResponse<CreateProductSetResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    logger.info('createProduct', { id, params });
    // const client = await buildShopifyClient();
    // if (client === undefined)
    // return { id, result: { type: 'error', error: 'Invalid Shopify client' } };
    const value: CreateProductSetResponse | void =
      await createProductSet_DYO_OG7(
        // client,
        params
      ).catch((err) => {
        logger.error('failed to create product', err);
      });
    if (!value)
      return {
        id,
        result: { type: 'error', error: JSON.stringify(Response.error()) },
      };
    logger.info('createProduct', { id, value });
    return { id, result: { type: 'success', value } };
  } catch (error) {
    logger.error('createProduct', { error });
    return { id, result: { type: 'error', error: 'Failed to create product' } };
  }
}

export async function publishProduct(
  id: string,
  params: PublishProductParams
): Promise<ServerResponse<PublishProductResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    logger.info('publishProduct', { id, params });
    // const client = await buildShopifyClient();
    // if (client === undefined)
    //   return { id, result: { type: 'error', error: 'Invalid Shopify client' } };
    const value: PublishProductResponse | void =
      await publishProductWithMedia_DYO_OG7(
        // client,
        params
      ).catch((err) => {
        logger.error('failed to publish product', { err });
      });
    if (!value)
      return {
        id,
        result: { type: 'error', error: JSON.stringify(Response.error()) },
      };
    logger.info('publish product complete');
    return { id, result: { type: 'success', value } };
  } catch (error) {
    logger.error('publishProduct', { error });
    return {
      id,
      result: { type: 'error', error: 'Failed to publish product' },
    };
  }
}

export async function getOrdersServer({ first, cursor }: Variables) {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    // const client = await buildShopifyClient();
    // if (client === undefined)
    //   return { id, result: { type: 'error', error: 'Invalid Shopify client' } };
    const value: OrdersResponse | void = await getOrders({
      first,
      cursor,
    }).catch((err) => {
      logger.error('failed to get Orders', { err });
    });
    if (!value)
      return {
        result: { type: 'error', error: JSON.stringify(Response.error()) },
      };
    logger.info('get orders complete');
    return { result: { type: 'success', value } };
  } catch (error) {
    logger.error('publishProduct', { error });
    return {
      result: { type: 'error', error: 'failed to get Orders' },
    };
  }
}
// The getInformationServer function retrieves product information based on an order id.
export async function getInformationServer(id: string) {
  // Initialize a logger for debugging purposes with a minimum level of DEBUG
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    // Retrieve environment configuration, including access to the object storage service
    const env = await getEnv();

    // Example commented-out code for handling event data and authorization:
    // const event = await getEvent();
    // const query = getQuery(event);
    // const header = getRequestHeader(event, 'Authorization');
    // if (header !== 'Bearer_WEARSHARE_TEMP_ORDERS') {
    //   return { result: { type: 'error', status: 403 } };
    // }
    // logger.info('get query', { query });

    // Retrieve the order data from the object store using a key pattern based on the provided id
    const orderShortHex = await env.WRSR_IO_DESIGNER_PRODUCTS.get(
      `orders/shorthex/${id}`
    );
    logger.info('orderShortHex', { orderShortHex });

    // If the order is not found, log the failure and return an error response
    if (!orderShortHex) {
      logger.info('orderShortHex was not found', { orderShortHex });
      return { status: 404, error: 'orderShortHex was not found' };
    }

    // Create a reader to stream the order data from the response body
    const orderReader = orderShortHex.body.getReader();
    const decoder = new TextDecoder();
    let orderResult = '';
    let done = false;

    // Read the streamed data until the stream is complete
    while (!done) {
      const { value, done: readerDone } = await orderReader.read();
      if (value) {
        // Decode the chunk and append it to the orderResult string
        orderResult += decoder.decode(value, { stream: true });
      }
      // Update the done flag based on the reader's status
      done = readerDone ?? true;
    }

    // Once the stream is finished, release the reader's lock
    if (done) orderReader.releaseLock();
    logger.info('orderResult', { orderResult });

    // Parse the order JSON string into an object matching the InputRow interface
    const parseOrder: InputRow = JSON.parse(orderResult);
    logger.info('get parseOrder', { parseOrder });

    let bandId;

    if (!parseOrder.bandId) {
      const imageId = await env.WRSR_IO_DESIGNER_PRODUCTS.get(
        `/bands/byproductid/${parseOrder.productId}`
      );
      logger.info('imageId', { imageId });

      // If no band mapping is found, log and return an error response
      if (!imageId) {
        logger.info('imageId was not found', { imageId });
        return { status: 404, error: 'imageId was not found' };
      }

      // Create a reader to stream the imageId mapping data
      const productIdReader = imageId.body.getReader();
      let productIdResult = '';
      done = false;
      while (!done) {
        const { value, done: readerDone } = await productIdReader.read();
        if (value) {
          // Decode and accumulate the productId mapping data
          productIdResult += decoder.decode(value, { stream: true });
        }
        done = readerDone ?? true;
      }
      // Release the reader's lock for the productId data
      if (done) productIdReader.releaseLock();
      logger.info('productIdResult', { productIdResult });

      // Retrieve the band mapping using the bandId from the parsed order data
      bandId = await env.WRSR_IO_DESIGNER_PRODUCTS.get(
        `/bands/byimageid/${productIdResult}`
      );
    } else {
      // Retrieve the band mapping using the bandId from the parsed order data
      bandId = await env.WRSR_IO_DESIGNER_PRODUCTS.get(
        `/bands/byimageid/${parseOrder.bandId}`
      );
    }

    logger.info('bandId', { bandId });

    // If no band mapping is found, log and return an error response
    if (!bandId) {
      logger.info('bandId was not found', { bandId });
      return { status: 404, error: 'bandId was not found' };
    }

    // Create a reader to stream the bandId mapping data
    const bandIdReader = bandId.body.getReader();
    let bandIdResult = '';
    done = false;
    while (!done) {
      const { value, done: readerDone } = await bandIdReader.read();
      if (value) {
        // Decode and accumulate the bandId mapping data
        bandIdResult += decoder.decode(value, { stream: true });
      }
      done = readerDone ?? true;
    }
    // Release the reader's lock for the bandId data
    if (done) bandIdReader.releaseLock();
    logger.info('bandIdResult', { bandIdResult });

    // Retrieve the actual band object using the key obtained from the band mapping
    const bandObj = await env.WRSR_IO_DESIGNER_PRODUCTS.get(bandIdResult);
    logger.info('bandObj', { bandObj });

    // If the band object is not found, log and return an error response
    if (!bandObj) {
      logger.info('bandObj was not found', { bandObj });
      return { status: 404, error: 'bandObj was not found' };
    }

    // Create a reader to stream the band object's data
    const bandReader = bandObj.body.getReader();
    let bandResult = '';
    done = false;
    while (!done) {
      const { value, done: readerDone } = await bandReader.read();
      if (value) {
        // Decode and accumulate the band data
        bandResult += decoder.decode(value, { stream: true });
      }
      done = readerDone ?? true;
    }
    // Once reading is complete, release the reader's lock
    if (done) bandReader.releaseLock();
    logger.info('bandResult', { bandResult });

    // Parse the band JSON string into an object for further processing
    const parseBand = JSON.parse(bandResult);
    logger.info('get parseBand', { parseBand });

    logger.info('get information complete');
    // Return a successful result containing the product image URL, title, and description
    return {
      result: {
        type: 'success',
        data: {
          imgUrl: parseBand.uploadResult.result.value.url,
          title: parseBand.inputs.title,
          description: parseBand.inputs.description,
        },
      },
    };
  } catch (error) {
    // In case of any errors, log the error and return an error response
    logger.error('getInformation', { error });
    return { status: 404, error };
  }
}

// Define the InputRow interface which represents the structure of an order in the system
interface InputRow {
  orderNumber: string;
  orderId: string;
  lineItemId: string;
  variantId: string;
  productId: string;
  quantity: number;
  pack: number;
  bandId: string;
}
